.background-red-linear-gradient {
  background: linear-gradient(270deg, rgba(230, 69, 57, 0.00) -1.94%, rgba(230, 69, 57, 0.12) 99.96%);
}

.width-16px {
  min-width: 10px;
}

.height-32px {
  height: 32px;
}

.black-dashed-border {
  background-image: linear-gradient(#404040 55%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 5px 14px;
  background-repeat: repeat-y;
}

.grey-dashed-border {
  background-image: linear-gradient(#4E4E4E 55%, rgba(255, 255, 255, 0) 0%);
  opacity: 0.5;
  background-position: right;
  background-size: 5px 14px;
  background-repeat: repeat-y;
}

.background-404040 {
  background: #404040;
}

.parent-width_-68px {
  width: calc(100% - 68px)
}

.width-16px {
  width: 16px;
}