.actions-header-height {
  height: 73px;
}

.flex-basis-1 {
  flex-basis: 1;
}

.action-icon-height {
  height: 16px;
}

.height-2-px {
  height: 2px;
}

.left-2-px {
  left: 2px
}

.bottom-2-px {
  bottom: 2px
}

.height-and-width-for-1-digit-number {
  height: 12px;
  width: 12px;
}

.height-and-width-for-2-digit-number {
  height: 16px;
  width: 16px;
  border-radius: 10px;
}

.height-and-width-for-3-digit-number {
  height: 16px;
  line-height: 16px;
  width: 20px;
  border-radius: 10px;
}

.border-bottom-0p5-px-solid-grey {
  border-bottom: 0.5px solid #E5E5E5;
}

.align-items-flex-start {
  align-items: flex-start;
}


/* --- media queries --- LAPTOP ---*/
@media screen and (min-width: 576px) {
  .actions-header-height {
    height: 107px;
  }

  .action-icon-height {
    height: 24px;
  }

  .height-and-width-for-1-digit-number {
    height: 17px;
    width: 17px;
  }

  .height-and-width-for-2-digit-number {
    height: 23px;
    width: 24px;
  }

  .height-and-width-for-3-digit-number {
    height: 23px;
    width: 30px;
  }
}

.header-clinic-name-width {
  width: 85%
}

.max-width-50-percentage {
  max-width: 50%;
}

@media screen and (max-width: 360px) {
  .header-clinic-name-width {
    width: 75%
  }
}