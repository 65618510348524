.input-slider-line-height {
	height: 2px;
}

.input-slider-line-background {
	border-radius: 1px;
	background-color: #e5e5e5;
}

.slider-thumb {
	-webkit-appearance: none;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
}
.slider-thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	opacity: 0;
	cursor: pointer;
}

.slider-thumb::-moz-range-thumb {
	position: relative;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	opacity: 0;
	cursor: pointer;
}

.width-33-percentage {
	width: 32%;
}

.voice-recorder-contol-button-size {
	width: 48px;
	height: 48px;
}

.flex-basis-80-percentage {
	flex-basis: 80%;
}

.control-icon-width {
	height: 12px;
}

.severity-range-thumb-size {
	width: 16px;
	height: 16px;
}

.position-absolute-top {
	top: 9px;
}

.list-style-type-none {
	list-style-type: none;
}

.column-gap-3-percentage{
  column-gap: 3%;
}

.max-height-90-percentage {
	max-height: 90%;
}
.object-fit-contain{
	object-fit: contain;
}


.width-30-percentage{
  width: 30%;
}

.width-40-percentage{
  width: 40%;
}

@media screen and (min-width: 576px) {
	.control-icon-width {
		height: 24px;
	}

	.voice-recorder-contol-button-size {
		width: 72px;
		height: 72px;
	}

	.input-slider-line-height {
		height: 4px;
	}

	.severity-range-thumb-size {
		width: 24px;
		height: 24px;
	}
	.position-absolute-top {
		top: 5px;
	}
}
