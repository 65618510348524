.profileComplete-tile > p {
	max-width: max-content;
	display: inline;
	float: left;
	margin-left: 1em;
}

.profileComplete-tile > img {
	display: inline;
	float: left;
	height: 1em;
}

.profileComplete-tile > span {
	width: 1.2em;
	height: 1.2em;
	background-color: var(--primaryColor);
	float: right;
	border-radius: 0.2em;
	color: white;
	text-align: center;
}

.background-color-white {
	background-color: #ffff;
}

.max-width-150px{
	max-width: 150px;
}
