.height-width-5em {
	height: 5em;
	width: 5em;
}

.float-left {
	float: left;
}

.height-0P8em {
	height: 0.8em;
}

.width-20percentage {
	width: 20%;
}

.width-30percentage {
	width: 30%;
}

.width-50percentage {
	width: 50%;
}

.width-80percentage {
	width: 80%;
}

.max-height-900px {
	max-height: 900px;
}
.height-90-percent {
	height: 90%;
}
.flex-justify-content-space-around {
	display: flex;
	justify-content: space-around;
}
.max-height-width-400px {
	max-width: 400px;
	max-height: 400px;
}
.width-150px {
	width: 150px;
}
.hover-effect-image:hover {
	z-index: -1;
	background-color: #4e4e4e;
}
.circle {
	border-radius: 50%;
}
/* .crop-modal-width {
	max-width: 400px;
} */
.edit-image {
	position: relative;
}
.max-height-80vh {
	max-height: 80vh;
}

.margin-left-auto{
	margin-left: auto;
}

/* very small Mobile screens */
@media screen and (max-width: 400px) {
	/*Max 5 Notification will be displayed */
	/* .crop-modal-width {
		max-width: 300px;
	} */
}

/* onTab screen */
@media screen and (min-width: 575px) {
	/* .crop-modal-width {
		max-width: 500px;
	} */
}
