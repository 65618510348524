.header-height {
  height: 64px;
}

.height-width-14px {
  height: 14px;
  width: 14px;
}

.height-width-16px {
  height: 16px;
  width: 16px;
}

.border-radius-8px-for-list-top {
  border-radius: 8px 8px 0 0;
}

/* .border-radius-8px-for-list li:last-child {
  border-radius: 0 0 8px 8px;
} */

.border-bottom-1px-faded-gray li:first-child {
  border-bottom: 1px solid #e5e5e5;
}

.border-bottom-1px-solid-grey {
  border-bottom: 1px solid #e5e5e5;
}

.border-bottom-1px-faded-gray li:last-child {
  border-bottom: none;
}

.border-radius-4px {
  border-radius: 4px;
}

.top-20-percent {
  top: 20%;
}

.max-width-900px {
  max-width: 900px;
}

.max-width-630px {
  max-width: 630px;
}

.header-logo-height {
  height: 32px;
}

.support-icon-height {
  height: 16px;
}

.back-arrow-row-icon-size {
  height: 18px;
  width: 16px;
}

.settings-icon-height {
  height: 16px;
}

.check-mark-icon-height {
  height: 12px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.height-width-2P5em {
  height: 2.5em;
  width: 2.5em;
}
.height-width-2P7em {
  height: 2.7em;
  width: 2.7em;
}

/* .notification-wrapper {
	max-height: 340px;
	overflow: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	border-radius: 0 0 8px 8px;
}

.notification-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0;
} */

.footer-padding-horizontal-larger {
  padding: 0;
}

.header-prefix-text {
  display: inline-block;
  max-width: 170px;
}

.border-width-0P1-px {
  border-width: 0.1px;
}

.right-1px {
  right: 1px;
}

.top-50-percentage {
  top: 50%;
}

.transform-translateY-minus-50-percentage {
  transform: translateY(-50%);
}

.height-and-width-for-single-digit-number {
  height: 14px;
  width: 14px;
}

.height-and-width-for-double-digit-number {
  height: 16px;
  width: 16px;
  border-radius: 10px;
}

.height-and-width-for-triple-digit-number {
  height: 16px;
  line-height: 16px;
  width: 20px;
  border-radius: 10px;
}

.hr-border-style {
  border-top: 1px solid #e5e5e5;
  border-bottom: none;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.input-search-border-bottom-default {
  border: none;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.12);
  /* border-bottom: 1px solid #4e4e4e; */
}
.stepper-progress-anime {
  height: 2px;
  background-color: var(--primaryColor);
  width: 0;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

/* very small Mobile screens */
@media screen and (max-width: 360px) {
  /*Max 5 Notification will be displayed */
  /* .notification-wrapper {
		max-height: 260px;
	} */
}

/* onTab screen */
@media screen and (min-width: 575px) {
  .adaptive-header-height {
    height: 96px;
  }

  .adaptive-header-logo-height {
    height: 48px;
  }

  .adaptive-support-icon-height {
    height: 24px;
  }

  .adaptive-back-arrow-row-icon-size {
    height: 28px;
  }

  .settings-icon-height {
    height: 24px;
  }

  .check-mark-icon-height {
    height: 17px;
  }

  /*Max 5 Notification will be displayed */
  /* .notification-wrapper {
		max-height: 460px;
	} */

  .footer-padding-horizontal-larger {
    padding: 0 calc(var(--spacing) * 3);
  }

  .height-and-width-for-single-digit-number {
    height: 17px;
    width: 17px;
  }

  .height-and-width-for-double-digit-number {
    height: 23px;
    width: 24px;
  }

  .height-and-width-for-triple-digit-number {
    height: 23px;
    width: 30px;
  }
}
