.height-half-screen {
	height: 50vh;
}

.width-225px {
	width: 225px;
}

.hight-225px {
	height: 100px;
}

.flex-basis-33-percent {
	flex-basis: 33%;
}

.flex-grow {
	flex-grow: 1;
}
