.filter-icon-height-and-width {
  height: 32px;
  width: auto;
}

.border-bottom-primary-gradient {
  border-radius: 1px;
  border-bottom: 2px solid #00A000;
}

.padding-vertical-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.overflow-horizontal-scroll {
  overflow-x: scroll;
}

.overflow-horizontal-scroll::-webkit-scrollbar {
  -moz-appearance: none;
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-track {
  -moz-appearance: none;
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-thumb {
  -moz-appearance: none;
  appearance: none;
}

.max-width-550px {
  max-width: 550px;
}

.font-size-8px {
  font-size: 8px;
}

.padding-vertical-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding-horizontal-4px {
  padding-right: 4px;
  padding-left: 4px;
}

.box-shadow-secondary {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.border-right-1px {
  border-right: 1px solid #f1f1f1;
}

.width-80percentage {
  width: 80%;
}

.flex-basis-75-percentage {
  flex-basis: 75%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-0P5-em {
  gap: 0.5em;
}

.width-50-percentage {
  width: 50%;
}

.line-height-100-percentage {
  line-height: 100%;
}

.right-5px {
  right: 5px;
}

.border-bottom-2px {
  border-radius: 1px;
  border-bottom: 2px solid transparent;
}

.border-color-primary {
  border-color: #00A000;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.border-right-2px-lite-grey {
  border: none;
  border-right: 2px solid #e5e5e5;
}

.height-25px {
  height: 25px;
}

.flex-gap-0-point-5-em {
  gap: 0.5em
}

.padding-0-point-4-em {
  padding: 0.4em;
}