.height-48px {
	min-height: 48px;
}

.flex-align-items-end {
	display: flex;
	align-items: flex-end;
}

.file-input-box {
	background-color: transparent;
}

.file-input-box-padding {
	max-width: 100%;
	padding-top: 1em;
	padding-right: 2em;
	padding-bottom: 0.4em;
	padding-left: 0px;
}

.padding-right-4p7-em {
	padding-right: 4.7em;
}

.padding-right-0 {
	padding-right: 0;
}

.min-width-250px {
	min-width: 250px;
}

.file-input-box-border-bottom-default {
	border: none;
	border-bottom: 1px solid #757575;
}

.file-input-box:focus {
	outline: none;
}

/* 
.file-input-box:read-only,
.file-input-box:read-only ~ .file-input-box-label {
	top: -12px;
}

.file-input-box:read-only ~ .file-input-box-label {
	font-size: 14px;
} */

.file-input-box:required {
	box-shadow: none;
}

.file-input-box-label {
	font-size: 16px;
	pointer-events: none;
	top: 55%;
	transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	-webkit-transition: 0.2s ease all;
	text-transform: uppercase;
	transform: translateY(-50%);
	color: var(--secondaryColor);
}

.file-input-box-label.active {
	top: 12px;
	font-size: 10px;
	color: var(--secondaryColor);
}

.file-input-box-border-bottom {
	position: relative;
	display: block;
	width: 100%;
}

.file-input-box-border-bottom:before,
.file-input-box-border-bottom:after {
	content: "";
	height: 2px;
	width: 0;
	bottom: 0;
	position: absolute;
	transition: 0.2s ease all;
	background: transparent;
}

.file-input-box-border-bottom:before {
	left: 50%;
}

.file-input-box-border-bottom:after {
	right: 50%;
}

/* active state */
.file-input-box.active~.file-input-box-border-bottom:before,
.file-input-box.active~.file-input-box-border-bottom:after {
	width: 50%;
	background: #00a000;
}

/* error state */
.file-input-box.error~.file-input-box-border-bottom:before,
.file-input-box.error~.file-input-box-border-bottom:after {
	width: 50%;
	background: red;
}

.transform-translateY-25-percentage {
	transform: translateY(-25%);
}

.top-50-percentage {
	top: 50%;
}

.min-width-1em {
	min-width: 1em;
}

.height-width-1em {
	height: 1em;
	width: 1em;
}

.margin-left-8px {
	margin-left: 8px;
}

.suffix-button-border {
	border: 1px solid #e5e5e5;
}

.border-radius-0p7em {
	border-radius: 0.7em;
}

.text-transform-uppercase {
	text-transform: uppercase;
}

.circular-loader {
	width: 0.9em;
	height: 0.9em;
	background-color: #fff;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border: 2px solid var(--primaryColor);
	border-left-color: transparent;
	animation-name: spin;
	animation-duration: 0.7s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
	}
}

@media screen and (min-width: 576px) {
	.file-input-box-label {
		font-size: 24px;
	}

	.file-input-box-label.active {
		font-size: 15px;
	}
}