.line-height-140-percentage {
  line-height: 1.4;
}

.health-id-benefits-icons-size {
  height: 24px;
  width: 24px;
}

.border-1px-solid-f1f1f1 {
  border: 1px solid #f1f1f1;
}

.font-style-normal {
  font-style: normal;
}

.height-1px {
  height: 1px;
}

.max-width-150px {
  max-width: 150px;
}

.abdm-care-context-document-card-border-bottom li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.remaining-height-of-abdm-clinic-care-context-section {
  height: calc(100% - 90px);
}

@media screen and (min-width: 576px) {
  .health-id-benefits-icons-size {
    height: 32px;
    width: 32px;
  }

  .remaining-height-of-abdm-clinic-care-context-section {
    height: calc(100% - 135px);
  }
}
